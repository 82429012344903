export enum Paths {
  Home = "/",
  Home2 = "/2",
  Architecture = "/architecture",
  ArchitectureDetail = "/architecture/:id",
  Portfolio = "/portfolio",
  PortfolioDetail = "/portfolio/:id",
  About = "/about",
  Team = "/team",
  Design = "/design",
  Contact = "/contact",
  Mail = "/contact/mail/:type",
  DesignDetail = "/design/:id",
  Services = "/services",
  ServicesDetail = "/services/:id",
}
